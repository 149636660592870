@import url(https://fonts.googleapis.com/css2?family=Farro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Farro', sans-serif !important;
}
.mediabody{
    display:inline-flex; flex-direction:row; grid-gap:12px; gap:12px;
}



.mainHome{display:inline-flex; flex-direction:row; grid-gap:12px; gap:12px;}

.homeImg {
  width: 50%;
  height: auto;
}

.homeStyle{
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: #000068;
    /* z-index: 200; */
}



.textBox {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255,255,255,1);  
  z-index: 13;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 50vw;
  font-weight: 100;
  margin: 0 auto;
  margin-top: 100px;
  border-style: double;
  border-width: 10px;
  border-color: #000068;
  border-radius: 5%;
}

@media only screen and (max-width: 900px) {
  .textBox {
    width: 95vw;
    position: relative;
    margin-top: 0px;
  }
}


.textStyle{
    font-weight: bold;
    font-size: 50px;
    color: #ec3851;
    margin: 0 auto;
    word-wrap: break-word;
  }

  #border {
    border-bottom: solid 3px rgba(0,255,0,.75);
    position: absolute;
    right: 0px;
    width: 20px;
  }

  /* Animation */
  /* .textStyle {
    animation: animated-text 2s steps(30,end) 1s 1 normal both
  } */

  #border {
     -webkit-animation: animated-cursor 600ms steps(30,end) infinite;
             animation: animated-cursor 600ms steps(30,end) infinite;
  }

  /* text animation */

  @-webkit-keyframes animated-text{
    from{width: 0;}
    to{width: 100%;}
  }

  @keyframes animated-text{
    from{width: 0;}
    to{width: 100%;}
  }

  /* cursor animations */

  @-webkit-keyframes animated-cursor{
    from{border-bottom-color: rgba(0,255,0,.75);}
    to{border-bottom-color: transparent;}
  }

  @keyframes animated-cursor{
    from{border-bottom-color: rgba(0,255,0,.75);}
    to{border-bottom-color: transparent;}
  }

  /* https://stackoverflow.com/questions/18012420/draw-diagonal-lines-in-div-background-with-css */
.crossed {
  background:
      linear-gradient(to top left,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) calc(50% - 1.7px),
          rgba(239,71,111,1) 50%,
          rgba(0,0,0,0) calc(50% + 1.7px),
          rgba(0,0,0,0) 100%),
      linear-gradient(to top right,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) calc(50% - 1.7px),
          rgba(239,71,111,1) 50%,
          rgba(0,0,0,0) calc(50% + 1.7px),
          rgba(0,0,0,0) 100%);
}


.light {
    position: absolute;
    width: 0px;
    opacity: .75;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(255, 255, 255) 0px 0px 20px 2px;
  
    top: 100vh;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
      z-index: 10;
  }
  
  .x1{
  -webkit-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
   -webkit-transform: scale(1.0);
  transform: scale(1.0);
  }
  
  .x2{
  -webkit-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
  }
  
  .x3{
  -webkit-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(.5);
  transform: scale(.5);
  left: -15%;
  }
  
  .x4{
  -webkit-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
  }
  
  .x5{
  -webkit-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
  }
  
  .x6{
  -webkit-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-transform: scale(.8);
  transform: scale(.8);
  left: -81%;
  }
  
  .x7{
  -webkit-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
  }
  
  .x8{
  -webkit-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
  }
  
  .x9{
  -webkit-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
  }
  
  @-webkit-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
  }
  @keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
  }
  
  
.typer{
  display: inline-block;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #ff7385;
  border: none;
  /* border-radius: 15px;
  box-shadow: 0 9px rgb(224, 164, 164); */
}

a {
    color: black;
}

.typer:hover {background-color: #d9e1e9}

/* .typer:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
} */

.topic_div{
    left: 0;
    bottom: 0;
    right: 0;
    /* position: fixed; */
    margin: 0 auto;
    align-items: center;
    width: 50%;
    /* height: 100%; */
    background-color: white;
    margin: 35px;
    border-radius: 25px;


    z-index: 12;
}

.screenStyle{
    text-align: center;
    grid-gap:12px;
    gap:12px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    margin: auto;
    height: 100vh;
    font-weight: 100;
    background: #000068;;
    overflow-y: scroll;
    -webkit-animation: fadeIn 1 1s ease-out;
    animation: fadeIn 1 1s ease-out;
    z-index: 10;
}

.person__pic{
    max-width:350px;
    max-height:350px;
    width: auto;
    height: auto;
    z-index: 12;
    position: sticky;
}


.full__page{

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content:center;
    align-items: center;
    text-align: center;

    margin: auto;
    height: 100vh;
    font-weight: 100;
    background: #000068;
    overflow-y: scroll;
    -webkit-animation: fadeIn 1 1s ease-out;
    animation: fadeIn 1 1s ease-out;
    z-index: 10;
}



.leaders__page{
    padding-top: 2%;
    display: inline-block;
    margin: 0 auto;
    width: 71.8vh;
    padding-bottom: 2%;
}

.leaders__div{
    display: flex;
    /*margin: 0 auto;*/
    align-items: center;
    height: 100%;
    background-color: white;
    margin: 15px;
    border-radius: 25px;


    z-index: 12;
}

.person__pic{
    /* min-width:350px; */
    /* min-height:350px; */
    width: 40%;
    height: auto;
    z-index: 11;
}


@media only screen and (max-width: 900px) {
    .leaders__div{
      width: 90vw;
    }
    .person__pic {
        width: 75% !important;
        height: auto !important;
    }
  }
  


.bio__div{
    height: 100%;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 40px;

}

.bioText {
    font-size: 20px;
}


.person__pic{
    z-index: 11;
    /* position: sticky; */
    width: auto;
    height: 800px
}
.mainHome{display:inline-flex; flex-direction:row; grid-gap:12px; gap:12px;}
.grid {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .item {
    width: 30vw;
    min-height: 200px;
    background-color: rgb(255, 255, 255);
    border-style: double;
    border-width: 10px;
    border-color: #000068;
    border-radius: 5%;
  }

  .image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 35%;
  }

  @media only screen and (max-width: 900px) {
    .image {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 75%;
    }

    .item {
      width: 90vw;
    }
  }

  .projectsBody {
      margin: 0;
      height: 100vh;
      font-weight: 100;
      background: #000068;
      /*-webkit-overflow-Y: hidden;*/
      /*-moz-overflow-Y: hidden;*/
      /*-o-overflow-Y: hidden;*/
      overflow-y: scroll;
      -webkit-animation: fadeIn 1 1s ease-out;
      animation: fadeIn 1 1s ease-out;
  }

  .zUp {
      z-index: 11;
  }

.person__pic{
    z-index: 11;
    position: sticky;
    
}
.navbar-brand {
  display: flex;
  align-items: center;
}
.navbar-brand img {
  padding: 3px 14px;
}

#image{
  size: 70vhc;
}

.color-nav {
  background-color: white !important;
}

.color-navlink {
  /* background-color: #000068 !important; */
  color: #000068 !important;
}

#color-dropdown {
  color: #000068 !important;
}

#color-dropdown2 {
  color: #000068 !important;
}
