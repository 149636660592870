.typer{
  display: inline-block;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #ff7385;
  border: none;
  /* border-radius: 15px;
  box-shadow: 0 9px rgb(224, 164, 164); */
}

a {
    color: black;
}

.typer:hover {background-color: #d9e1e9}

/* .typer:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
} */

.topic_div{
    left: 0;
    bottom: 0;
    right: 0;
    /* position: fixed; */
    margin: 0 auto;
    align-items: center;
    width: 50%;
    /* height: 100%; */
    background-color: white;
    margin: 35px;
    border-radius: 25px;


    z-index: 12;
}

.screenStyle{
    text-align: center;
    gap:12px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    margin: auto;
    height: 100vh;
    font-weight: 100;
    background: #000068;;
    overflow-y: scroll;
    -webkit-animation: fadeIn 1 1s ease-out;
    -moz-animation: fadeIn 1 1s ease-out;
    -o-animation: fadeIn 1 1s ease-out;
    animation: fadeIn 1 1s ease-out;
    z-index: 10;
}
