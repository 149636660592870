.person__pic{
    max-width:350px;
    max-height:350px;
    width: auto;
    height: auto;
    z-index: 12;
    position: sticky;
}


.full__page{

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content:center;
    align-items: center;
    text-align: center;

    margin: auto;
    height: 100vh;
    font-weight: 100;
    background: #000068;
    overflow-y: scroll;
    -webkit-animation: fadeIn 1 1s ease-out;
    -moz-animation: fadeIn 1 1s ease-out;
    -o-animation: fadeIn 1 1s ease-out;
    animation: fadeIn 1 1s ease-out;
    z-index: 10;
}



.leaders__page{
    padding-top: 2%;
    display: inline-block;
    margin: 0 auto;
    width: 71.8vh;
    padding-bottom: 2%;
}

.leaders__div{
    display: flex;
    /*margin: 0 auto;*/
    align-items: center;
    height: 100%;
    background-color: white;
    margin: 15px;
    border-radius: 25px;


    z-index: 12;
}

.person__pic{
    /* min-width:350px; */
    /* min-height:350px; */
    width: 40%;
    height: auto;
    z-index: 11;
}


@media only screen and (max-width: 900px) {
    .leaders__div{
      width: 90vw;
    }
    .person__pic {
        width: 75% !important;
        height: auto !important;
    }
  }
  


.bio__div{
    height: 100%;
    width: 100%;
    justify-content: center;
    font-weight: bold;
    font-size: 40px;

}

.bioText {
    font-size: 20px;
}

