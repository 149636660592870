


.mainHome{display:inline-flex; flex-direction:row; gap:12px;}

.homeImg {
  width: 50%;
  height: auto;
}

.homeStyle{
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: #000068;
    /* z-index: 200; */
}



.textBox {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255,255,255,1);  
  z-index: 13;
  text-align: center;
  height: fit-content;
  width: 50vw;
  font-weight: 100;
  margin: 0 auto;
  margin-top: 100px;
  border-style: double;
  border-width: 10px;
  border-color: #000068;
  border-radius: 5%;
}

@media only screen and (max-width: 900px) {
  .textBox {
    width: 95vw;
    position: relative;
    margin-top: 0px;
  }
}


.textStyle{
    font-weight: bold;
    font-size: 50px;
    color: #ec3851;
    margin: 0 auto;
    word-wrap: break-word;
  }

  #border {
    border-bottom: solid 3px rgba(0,255,0,.75);
    position: absolute;
    right: 0px;
    width: 20px;
  }

  /* Animation */
  /* .textStyle {
    animation: animated-text 2s steps(30,end) 1s 1 normal both
  } */

  #border {
     animation: animated-cursor 600ms steps(30,end) infinite;
  }

  /* text animation */

  @keyframes animated-text{
    from{width: 0;}
    to{width: 100%;}
  }

  /* cursor animations */

  @keyframes animated-cursor{
    from{border-bottom-color: rgba(0,255,0,.75);}
    to{border-bottom-color: transparent;}
  }

  /* https://stackoverflow.com/questions/18012420/draw-diagonal-lines-in-div-background-with-css */
.crossed {
  background:
      linear-gradient(to top left,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) calc(50% - 1.7px),
          rgba(239,71,111,1) 50%,
          rgba(0,0,0,0) calc(50% + 1.7px),
          rgba(0,0,0,0) 100%),
      linear-gradient(to top right,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) calc(50% - 1.7px),
          rgba(239,71,111,1) 50%,
          rgba(0,0,0,0) calc(50% + 1.7px),
          rgba(0,0,0,0) 100%);
}
