.mainHome{display:inline-flex; flex-direction:row; gap:12px;}
.grid {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .item {
    width: 30vw;
    min-height: 200px;
    background-color: rgb(255, 255, 255);
    border-style: double;
    border-width: 10px;
    border-color: #000068;
    border-radius: 5%;
  }

  .image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 35%;
  }

  @media only screen and (max-width: 900px) {
    .image {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 75%;
    }

    .item {
      width: 90vw;
    }
  }

  .projectsBody {
      margin: 0;
      height: 100vh;
      font-weight: 100;
      background: #000068;
      /*-webkit-overflow-Y: hidden;*/
      /*-moz-overflow-Y: hidden;*/
      /*-o-overflow-Y: hidden;*/
      overflow-y: scroll;
      -webkit-animation: fadeIn 1 1s ease-out;
      -moz-animation: fadeIn 1 1s ease-out;
      -o-animation: fadeIn 1 1s ease-out;
      animation: fadeIn 1 1s ease-out;
  }

  .zUp {
      z-index: 11;
  }

.person__pic{
    z-index: 11;
    position: sticky;
    
}